.App {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 6rem;
    line-height: 1.625;
    box-sizing: border-box;
    padding-bottom: 20px;
}



header {
    color: #E2E8F0;
    position: fixed;
    flex-direction: column;
    justify-content: center;

}

body {
    padding-left: 6vw;
    padding-right: 6vw;
    background: linear-gradient(111.4deg, rgba(7, 7, 9, 1) 6.5%, rgba(27, 24, 113, 1) 93.2%);
}

.body-section {
    width: 50%;
    align-items: right;
    justify-content: space-between;
    display: flex;
    display: flex;
    flex-direction: column;
}

a {
    color: inherit;
    text-decoration: none;
}

a:visited {
    color: inherit;
}

@media (max-width: 1200px) {
    .App {
        display: flex;
        flex-direction: column;
        box-sizing: content-box;
    }
}

@media (min-width: 1201px) {
    .s h1 {
        position: -webkit-sticky;
        position: sticky;
        z-index: 10;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        font-size: 30px;
        font-weight: 600;
    }

    .section-header {
        color: #E2E8F0;
    }
}

@media (max-width: 1200px) {
    .s h1 {
        position: -webkit-sticky;
        position: sticky;
        z-index: 10;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        font-size: 20px;
        font-weight: 600;
        top: 42px;
        margin-left: -400px;
        padding-left: 400px;
        justify-items: center;
        width: 90vw;
        margin-bottom: -20px;
    }

    .section-header {
        color: #E2E8F0;
        position: -webkit-sticky;
        position: sticky;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}