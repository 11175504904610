.Projects {
    text-align: left;
    align-content: center;
    display: flex;
    flex-direction: column;
}

.project-card {
    transition: background-color .1s ease-in-out .1s;
    transition: border .1s ease-in-out .1s;
    transition: border .1s ease-in-out .1s;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 20px 40px;
}

.project-card:hover {
    background-color: rgba(68, 65, 152, 0.2);
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.p-name {
    color: #E2E8F0;
}

.p-icon {
    transition: transform .05s ease-in-out .05s;
    transform: translate(0, -1px);
}

.project-card:hover .p-icon {
    transform: translate(4px, -4px);
}

.p-skills {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.project-card:hover .p-name {
    color: #d1efb3;
}

.p-desc {
    color: #b3c2e1;
}

.p-links,
.p-links:visited {
    color: #E2E8F0;
}

.p-links:hover {
    color: #d1efb3;
}

.skill-0,
.skill-1,
.skill-2,
.skill-3,
.skill-4,
.skill-5 {
    font-size: 13px;
    font-weight: 400;
    color: #d1efb3;
    padding: 5px 10px;
    background: rgba(168, 207, 128, 0.4);
    border-radius: 50px;
    margin: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media (max-width: 1200px) {

    .project-card {
        padding: 0px 0px;
        margin-bottom: 40px;
        margin-top: 40px;
    }

    .project-card:hover {
        background-color: initial;
        border-radius: initial;
        box-shadow: initial;
        color: initial;
    }

    .p-desc {
        color: #b3c2e1;
    }

    .project-card:hover .p-icon {
        transform: translate(0, -1px);
    }

    .project-card:hover .p-name {
        color: #E2E8F0;
    }

    .p-name:hover {
        color: #d1efb3 !important;
    }

    .p-name:hover .p-icon {
        transform: translate(4px, -4px);
    }
}