.my-name {
    font-size: 35pt;
    width: 100%;
    height: 100%;
    width: max-content;
}

.git-icon,
.linked-icon,
.mail-icon {
    padding-right: 20px;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.git-icon:hover,
.linked-icon:hover,
.mail-icon:hover {
    color: white !important;
}

@media (max-width: 1200px) {
    .my-name {
        font-size: 30pt;
        width: fit-content;
    }

    header {
        position: static;
    }
}