.Experience {
    text-align: left;
    align-content: center;
    display: flex;
    flex-direction: column;
}

.experience-card {
    transition: background-color .1s ease-in-out .1s;
    transition: border .1s ease-in-out .1s;
    transition: border .1s ease-in-out .1s;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 12px;
    margin-top: 12px;
    padding: 20px 40px;
}

.experience-card:hover {
    background-color: rgba(68, 65, 152, 0.2);
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.e-company {
    color: #E2E8F0;
}

.e-icon {
    transition: transform .05s ease-in-out .05s;
    transform: translate(0, -1px);
}

.experience-card:hover .e-icon {
    transform: translate(4px, -4px);
}

.e-skills {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.experience-card:hover .e-company {
    color: #d1efb3;
}

.e-time {
    color: #b3c2e1;
    font-weight: 500;
    font-size: 12px;
}

.e-desc {
    color: #b3c2e1;
}

.e-links,
.e-links:visited {
    color: #E2E8F0;
}

.e-links:hover {
    color: #d1efb3;
}

.skill-0,
.skill-1,
.skill-2,
.skill-3,
.skill-4,
.skill-5 {
    font-size: 13px;
    font-weight: 400;
    color: #d1efb3;
    padding: 5px 10px;
    background: rgba(168, 207, 128, 0.4);
    border-radius: 50px;
    margin: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

img {
    object-fit: cover;
    height: 60px;
    width: fit-content;
}

@media (max-width: 1200px) {

    .experience-card {
        padding: 0px 0px;
        margin-bottom: 40px;
        margin-top: 40px;
    }

    .experience-card:hover {
        background-color: initial;
        border-radius: initial;
        box-shadow: initial;
        color: initial;
    }

    .experience-card:hover .e-icon {
        transform: translate(0, -1px);
    }

    .experience-card:hover .e-company {
        color: #E2E8F0;
    }

    .e-company:hover {
        color: #d1efb3 !important;
    }

    .e-company:hover .e-icon {
        transform: translate(4px, -4px);
    }

    img {
        object-fit: cover;
        height: 40px;
        width: fit-content;
        padding-right: 15px;
    }
}

/*resume button*/
.resume {
    font-size: 15px;
    width: fit-content;
    color: white;
    font-weight: 600;
    text-decoration: none;
    display: inline-block;
    position: relative;
    padding-bottom: 3px;
}

.resume::after {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    /* thickness of underline */
    background-color: #d1efb3;
    /* color of underline */
    left: 50%;
    right: 50%;
    bottom: 0;
    transition: left 0.3s ease-out, right 0.3s ease-out;
}

.resume:hover::after {
    left: 0;
    right: 0;
}