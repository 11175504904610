.menu {
    position: fixed;
    justify-content: center;
    align-items: flex-end;
    right: -160px;
    top: 0;
    bottom: 0;
    width: 200px;
    background-color: #d1efb3;
    overflow-y: auto;
    transition: right 0.2s ease-in-out, width 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: right;
    z-index: 50;
    color: #121212;
}

.menu.show {
    right: 0;
    padding-right: 45px;
}

.menu-icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 30px;
    margin-left: 7px;
}


.menu-link {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: right;
    padding: 8px 18px;
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 16px;
    transition: font-size 0.2s ease-in-out;
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;
}

.menu-link.active {
    border: 2px solid #121212;
    border-radius: 12px;
}

@media (max-width: 1200px) {
    .menu {
        justify-content: center;
        align-items: center;
        position: fixed;
        overflow-y: hidden;
        top: -180px;
        bottom: 0;
        left: 0;
        height: 222px;
        width: 100%;
        transition: top 0.2s ease-in-out, height 0.2s ease-in-out;
        z-index: 50;
    }

    .menu.show {
        top: 0;
        padding-right: 0px;
    }

    .menu-toggle {
        position: absolute;
        bottom: 4px;
        right: 20px;
        top: 176px;
        cursor: pointer;
        font-size: 30px;
        transition: top 0.5s ease-in-out, height 0.5s ease-in-out;
        transition: bottom 0.5s ease-in-out, height 0.5s ease-in-out;
    }

    .menu-toggle.active {
        top: -2.75px;
        font-size: 35px;
        right: 20px;
    }
}